import React from 'react'
import { bitgit, chaingpt, ejit, hhh, kucoin3, loack, mexc2, pancakes, pool, seedify } from '../services/image'

function SubmenuCard() {
  return (
      <div className="submenu  rounded-[20px] overflow-hidden w-[275px]  p-[1px] mx-auto">
          <div className='bg-[#0D0D17] rounded-[20px] p-3.5'>
              <div className='grid grid-cols-2 gap-3'>
                  <div className='flex flex-col'>
                      <div>
                          <h3 className='text-[#A9C5E4] text-xs/4 pr mb-4'>DEX | CEX</h3>
                          <div className='flex flex-col gap-2'>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={kucoin3} alt="" className='w-full h-full' />
                                  </div>
                                  <h3 className='text-[10px] text-white pr'>Kucoin</h3>
                              </div>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={bitgit} alt="" className='w-full h-full' />
                                  </div>
                                  <h3 className='text-[10px] text-white pr'>Bitgit</h3>
                              </div>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={mexc2} alt="" className='w-full h-full' />
                                  </div>
                                  <h3 className='text-[10px] text-white pr'>Mexc</h3>
                              </div>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={pancakes} alt="" className='w-full h-full' />
                                  </div>
                                  <h3 className='text-[10px] text-white pr'>Pancakeswap</h3>
                              </div>
                          </div>
                      </div>
                      <div className='mt-4'>
                          <h3 className='text-[#A9C5E4] text-xs/4 pr mb-2.5'>Audited</h3>
                          <div className='flex flex-col gap-2'>
                              <div className='flex flex-row justify-between items-center'>
                                  <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                      <div className='w-5 h-5'>
                                          <img src={kucoin3} alt="" className='w-full h-full' />
                                      </div>
                                      <h3 className='text-[10px] text-white pr'>Kucoin</h3>
                                  </div>
                                  <div className="flex flex-col">
                                      <h3 className='text-[#616586] text-[8px] pr mb-0'>98.03%</h3>
                                      <div className='w-10 h-2 rounded-full bg-[#616586]'>
                                          <div className='submenu rounded-s-full w-10/12 h-full'></div>
                                      </div>
                                  </div>
                              </div>
                              <div className='flex flex-row justify-between items-center'>
                                  <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                      <div className='w-5 h-5'>
                                          <img src={hhh} alt="" className='w-full h-full' />
                                      </div>
                                      <h3 className='text-[10px] text-white pr'>Bitgit</h3>
                                  </div>
                                  <div className="flex flex-col">
                                      <h3 className='text-[#616586] text-[8px] pr mb-0'>100%</h3>
                                      <div className='w-10 h-2 rounded-full bg-[#616586]'>
                                          <div className='submenu rounded-s-full w-10/12 h-full'></div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col'>
                      <div>
                          <div className='flex items-start flex-row gap-2 mb-2'>
                              <h3 className='text-[#A9C5E4] text-xs/4 pr mb-2'>IDO </h3>
                              <div className='px-[7px] submenu rounded-lg text-[#0C0C15] py-1  text-[10px] '>Sold Out</div>
                          </div>
                          <div className='flex flex-col gap-2'>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={chaingpt} alt="" className='w-full h-full' />
                                  </div>
                                  <div className='flex-col flex '>
                                      <h3 className='text-[#A9C5E4] text-xs/4 pr'>ChainGPT</h3>
                                      <p className='text-[#979797] text-[8px] pr'>sold out in 20 sec</p>
                                  </div>
                              </div>

                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={ejit} alt="" className='w-full h-full' />
                                  </div>
                                  <div className='flex-col flex '>
                                      <h3 className='text-[#A9C5E4] text-xs/4 pr'>Enjistarter</h3>
                                      <p className='text-[#979797] text-[8px] pr'>sold out in 20 sec</p>
                                  </div>
                              </div>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={seedify} alt="" className='w-full h-full' />
                                  </div>
                                  <div className='flex-col flex '>
                                      <h3 className='text-[#A9C5E4] text-xs/4 pr'>Seedify</h3>
                                      <p className='text-[#979797] text-[8px] pr'>sold out in 20 sec</p>
                                  </div>
                              </div>
                              <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                                  <div className='w-5 h-5'>
                                      <img src={pool} alt="" className='w-full h-full' />
                                  </div>
                                  <div className='flex-col flex '>
                                      <h3 className='text-[#A9C5E4] text-xs/4 pr'>PoolzFinance</h3>
                                      <p className='text-[#979797] text-[8px] pr'>sold out in 20 sec</p>
                                  </div>
                              </div>

                          </div>
                      </div>
                      <div className='mt-4'>
                          <h3 className='text-[#A9C5E4] text-xs/4 pr mb-2.5'>Secured</h3>
                          <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                              <div className='w-5 h-5'>
                                  <img src={loack} alt="" className='w-full h-full' />
                              </div>
                              <div className='flex-col flex '>
                                  <h3 className='text-[#A9C5E4] text-xs/4 pr'>Team Finance</h3>
                                  <p className='text-[#979797] text-[8px] pr'>Liquidity Lock</p>
                              </div>
                          </div>
                          <div className='flex flex-row gap-1.5 items-center h-7 cursor-pointer'>
                              <div className='w-5 h-5'>
                                  <img src={loack} alt="" className='w-full h-full' />
                              </div>
                              <div className='flex-col flex '>
                                  <h3 className='text-[#A9C5E4] text-xs/4 pr'>Team Finance</h3>
                                  <p className='text-[#979797] text-[8px] pr'>Liquidity Lock</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default SubmenuCard