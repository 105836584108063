import React from 'react'
import { linkedin, temamember, twitterx } from '../services/image'

function CoreTeam() {
    return (
        <div className='md:p-[22px] p-3 rounded-[20px] relative bg-[#151524] group overflow-hidden'>
            <div className="h-[240px] ">
                <img src={temamember} alt="" className='w-full h-full md:object-contain object-fill' />
            </div>
            <h3 className='mt-5 mb-1 text-grad5 text-trans text-2xl/8 pm'>Andrei Tara</h3>
            <p className='text-base/6 text-white pbd mb-5'>Founder & CEO</p>
            <p className='text-[#A7A8B1] line-clamp-5'>Tech Entrepreneur & Polymath.
                Core Developer Elrond, Blockchain
                Researcher SkyyNetwork. CEO &
                Founder BuyPal, TLabs. BS CS, MSc
                CS, PhD candidate CS.</p>
            <div className="absolute bg-[#1D1D2E] rounded-2xl flex flex-row items-center justify-start gap-3 py-3.5 px-[18px] bottom-0 z-10 left-0 right-0 transition-transform transform invisible group-hover:visible translate-y-full group-hover:translate-y-0">
                <button className='border-none bg-[#FFBE98] rounded-full w-10 h-10 flex items-center justify-center '>
                    <div className='w-5 h-5'>
                        <img src={twitterx} className='w-full h-full' alt="" />
                    </div>
                </button>
                <button className='border-none bg-[#FFBE98] rounded-full w-10 h-10 flex items-center justify-center '>
                    <div className='w-5 h-5'>
                        <img src={linkedin} className='w-full h-full' alt="" />
                    </div>
                </button>
            </div>
        </div>
    )
}

export default CoreTeam