import React, { useEffect } from 'react'
import Header from '../component/Header'
import { battleground, Blog1, Blog2, Blog3, Blog4, Blog5, Blog6 } from '../services/image'
import BlogCard from '../component/BlogCard'
import Footer from '../component/Footer'
import { WOW } from 'wowjs'
import 'animate.css';
import blogData from '../component/BlogData';

let text = [1, 2, 3, 4, 5, 6];
const BlogImg = [Blog1, Blog2, Blog3, Blog4, Blog5, Blog6]

function Blog() {

    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, []);

    return (
        <div>
            <div className="bg_team bg-size bg-no-repeat bg-center ">
                <div className='lg:mx-[59px] mx-4 '>
                    <Header />
                    <div className="lg:pt-28 md:pt-14 pt-7">
                        <div className="md:w-11/12 w-full mx-auto flex flex-col justify-center items-center">
                            <p className='text-grad7 text-trans text-4xl sm:text-[45px] md:text-[60px] lg:text-[74px]/[81px] pr text-center wow animate__animated animate__fadeInUp'>Latest news from Openfabric</p>
                        </div>
                        <div className='flex flex-row w-full lg:w-11/12 mx-auto mt-20 wow animate__animated animate__fadeInUp'>
                            <div className='h-[300px] sm:h-[240px] md:h-[380px]  lg:w-1/2  w-1/2 md:w-1/2  rounded-s-[30px] overflow-hidden'>
                                <img src={Blog1} className='w-full h-full object-cover' alt="" />
                            </div>
                            <div className='h-[300px] sm:h-[240px] md:h-[380px] flex-1 bg-no-repeat bg-center bg-gradient md:p-10 p-5'>
                                <p className='md:gap-10 gap-2 flex flex-row flex-wrap items-center'>
                                    <span className='text-[#D9E1EA] text-base/6 pr'>Feb 23, 2024</span>
                                    <span className='text-[#D9E1EA] text-base/6 pr'>15 minutes</span>
                                </p>
                                <p className='md:mt-[31px] mt-3 text-white md:text-2xl/8 text-sm pm mb-1.5'>Why Decentralized AI is better than Centralized AI</p>
                                <p className='text-[#D9E1EA] text-sm md:text-base/6 pr lg:line-clamp-6 sm:line-clamp-5 line-clamp-3'>In today’s world of Artificial Intelligence, users often grapple
                                    with fundamental d of Artificial Intelligence,d of Artificial Intelligence, users often grapple
                                    with fundamental  users often grapple
                                    with fundamental  questions, seeking elusive answers in the…</p>

                                <a href="" className='flex-row items-center flex text-[#FFBE98] gap-1 text-sm sm:text-base/6 pm underline md:mt-8 mt-4'>
                                    <span className=''>Learn More &nbsp;&gt;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="other_blog_wrapp">
                <div className='lg:mx-[59px] mx-4 '>
                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4 mt-10 ">
                        {blogData.map((blog) => (
                            <BlogCard key={blog.id} blog={blog} />
                        ))}
                    </div>
                    <button className='clip mx-auto game_download rounded-lg flex gap-1.5 items-center justify-center h-[72px] !w-max px-5 mt-12  rounded-br-[24px]'>
                        <span className='text-base text-white text-center pbd tracking-wide'>Read More</span>
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Blog