import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { WOW } from 'wowjs'
import 'animate.css';
import { useGSAP } from "@gsap/react";

const TopHeading = memo((props) => {
    return (
        <h3 className='wow animate__animated animate__fadeInUp text-lg md:text-xl lg:text-2xl/[60px] pr text-white text-center mt-8 relative z-10'>{props.text}</h3>
    )
})

TopHeading.propTypes = {}

export default TopHeading