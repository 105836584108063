import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { WOW } from 'wowjs'
import 'animate.css';
import { useGSAP } from "@gsap/react";

const GradientText = memo((props) => {
  return (
    <p className='wow animate__animated animate__fadeInUp text-market lg:text-4xl text-3xl xl:text-[46px]/[63.25px] pr text-center bg_grad relative z-10'>{props.props}</p>
  )
})

GradientText.propTypes = {}

export default GradientText