import React, { useRef, useState } from 'react'
import { playbtnsvg, postervideo } from '../services/image';

const VideoCard = ({ url }) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef1 = useRef(null);

    const handleVideoToggle = () => {
        const video = videoRef1.current;
        if (!video) return;

        if (video.paused || video.ended) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

    const handleVideoClick = () => {
        const video = videoRef1.current;
        if (!video) return;

        if (isPlaying) {
            video.pause();
            setIsPlaying(false);
        }
    };

    return (
        <>
            <video ref={videoRef1} src={url} onClick={handleVideoClick} className='object-fill h-full w-full' height={200} width={'100%'}></video>
            {!isPlaying && (<button onClick={handleVideoToggle} className='bg-[#09090F] rounded-full h-10 md:h-[56px] md:w-[56px] w-10 absolute top-[37%] flex items-center justify-center left-[42%] homeVideoPlayBtn'>
                <div className='md:w-4 md:h-4 h-3 w-3'>
                    <img src={playbtnsvg} alt="" />
                </div>
            </button>
            )
            }
        </>
    )
}

export default VideoCard