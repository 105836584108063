import React from 'react'
import { kucoin } from '../services/image'

function ReviewCard() {
    return (
        <div>
            <div className=" px-3 review_section relative lg:w-1/2 md:w-11/12 w-11/12 mx-auto">
                <p className=' text-xl md:text-2xl/8 pr text-white text-start lg:line-clamp-6 md:line-clamp-4 sm:line-clamp-5 line-clamp-5'>
                    I can attest to the exceptional capabilities of the team and
                    technology they are developing. Their work is truly cutting-
                    edge and I have no doubt that it has the ability to move the
                    industry forward. I am impressed with the dedication and
                    talent of the team behind it, and I am excited to be a part of
                    this investment.</p>
            </div>
            <div className='flex md:mt-[50px] mt-5 flex-row justify-center md:justify-start items-center gap-[29px] lg:w-1/2 md:w-11/12 w-full mx-auto'>
                <div className='w-[88px] h-[88px] overflow-hidden rounded-full border_people '>
                    <img src={kucoin} alt="" />
                </div>
                <div >
                    <p className='text-grad5 text-trans text-2xl/8 pm mb-0 text-start'>Sayantan Mitra</p>
                    <h5 className='text-white text-base/8 pr text-start'>
                        Partner at Cogitent Ventures
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard