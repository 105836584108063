import React, { useEffect, useRef } from 'react'
import TopHeading from './TopHeading';
import GradientText from './GradientText';
import { charactor2 } from '../services/image';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { WOW } from 'wowjs'
import 'animate.css';
import { Suspense } from 'react';



function MarketPlaceSwiper() {
    const firstVideo = useRef(null);

    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, [])

    useEffect(() => {
        const swiper = new Swiper('.marketplaceswiper', {
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }, []);

    function playVideo() {
        if (firstVideo.current.paused) {
            firstVideo.current.play();
        }
    }

    function pauseVideo() {
        if (!firstVideo.current.paused) {
            firstVideo.current.pause();
        }
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className='horzontal_slider_wrapper'>
                <div className="lg:mx-[59px] mx-4 ">
                    <div className="md:pt-10 wow animate__animated animate__fadeInUp animate__slow">
                        <TopHeading text={'Arena Tactics: Pick Your Play Style'} />
                        <div className='lg:w-10/12 w-full mx-auto'>
                            <GradientText props={`Game Modes`} />
                        </div>
                        <div className='mt-10 md:mt-[60px] '>
                            <div className="swiper marketplaceswiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className='swiper_image_overflow lg:w-3/5 mx-auto'>
                                            {/* <video ref={firstVideo} src={require('../assets/homepagegif.mp4')} poster={charactor2} className='object-contain h-100 w-100' height={200} width={'100%'} onMouseEnter={() => playVideo()}
                                                onMouseLeave={() => pauseVideo()}>
                                            </video> */}
                                            <img src={charactor2} alt="" className='w-full h-full' />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className='swiper_image_overflow lg:w-3/5 mx-auto'>
                                            <img src={charactor2} alt="" className='w-full h-full' />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-pagination !hidden"></div>
                                <div className="swiper-button-prev md:!w-[60px] md:!h-[60px] lg:!left-[10%] PREV rounded-md"></div>
                                <div className="swiper-button-next NEXT md:!w-[60px] md:!h-[60px] lg:!right-[10%] rounded-md"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

export default MarketPlaceSwiper