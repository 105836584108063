import React, { useEffect } from 'react'
import Header from '../component/Header'
import { charc3, trading4, tranding3 } from '../services/image'
import Footer from '../component/Footer'
import { WOW } from 'wowjs'
import 'animate.css';
import { useParams, useLocation } from 'react-router-dom';
import blogData from '../component/BlogData';

function BlogDetail() {
    const location = useLocation();
    const { blog } = location.state;
    console.log('printing blog', blog)
    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, []);

    return (
        <div className="blogDetail">
            <div className="bg_team bg-size bg-no-repeat bg-center ">
                <div className='lg:mx-[59px] mx-4 '>
                    <Header />
                    <div className="lg:pt-28 md:pt:14 pt-7">
                        <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center items-center">
                            <p className='text-grad7 text-trans md:text-3xl text-2xl Lg:text-[46px]/[69px] pr text-center wow animate__animated animate__fadeInUp'>{blog.title}</p>
                            <div className="mt-4 justify-center flex flex-row items-center gap-10 flex-wrap wow animate__animated animate__fadeInUp">
                                <p className='text-[#A9C5E4] text-base/6 text-center '>{blog.date}</p>
                                {/* <p className='text-[#A9C5E4] text-base/6 text-center '>9 minutes read</p> */}
                            </div>
                            <div className="w-full lg:h-[450px] md:h-96 sm:h-72 h-60 rounded-3xl overflow-hidden mt-10 wow animate__animated animate__fadeInUp">
                                <img src={blog.imageUrl} className='w-full h-full object-cover' alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="lg:mx-[59px] mx-4">
                <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center items-center mt-10 wow animate__animated animate__fadeInUp">
                    <p className='text-white text-[18px]/[28px] pr md:text-start text-center'>{blog.content}</p>
                </div>
                {blog.sub_heading1 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading1}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc1}</p>
                    </div>
                }
                {blog.sub_heading2 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading2}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc2}</p>
                    </div>
                }
                {blog.sub_heading3 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading3}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc3}</p>
                    </div>
                }
                {blog.sub_heading4 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading4}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc4}</p>
                    </div>
                }
                {blog.sub_heading5 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading5}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc5}</p>
                    </div>
                }
                 {blog.sub_heading6 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading6}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc6}</p>
                    </div>
                }
                {blog.sub_heading7 &&
                    <div className="lg:w-3/5 md:w-11/12 w-full mx-auto flex flex-col justify-center mt-7 md:mt-11 wow animate__animated animate__fadeInUp">
                        <h3 className='text-grad11 text-2xl text-center md:text-start md:text-3xl lg:text-[32px]/[40px] pm text-trans mb-6 '>{blog.sub_heading7}</h3>
                        <p className='text-white text-[18px]/[28px] pr md:text-start text-center mb-[30px]'>{blog.sub_desc7}</p>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default BlogDetail