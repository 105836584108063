import React from 'react'
import { bitcoin, discord, github, hackgu, insta, linkedin, lizert, meta, metro, siteLogo, siteLogo2, telegram, telegram2, tiktok, twitterx, youtube } from '../services/image'
import { Link, NavLink } from 'react-router-dom'

function Footer() {
    return (
        <div className='Arma_updates'>
            <div className='bg-spring md:bg-cover bg-contain bg-center bg-no-repeat  sm:pt-24 pt-10'>
                <div className="flex md:flex-row flex-col items-center  justify-center mx-14 lg:pb-[110px] md:pb-14 sm:pb-7 pb-7">
                    <div className="md:w-1/2 w-full">
                        <div className='lg:w-[55%] sm:w-1/2 mx-auto'>
                            <img src={bitcoin} className='w-full h-full object-contain' alt="" />
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full">
                        <h3 className='pr text-base md:text-2xl/[60px] text-white'>Join the Battle: Stay Updated!</h3>
                        <p className='lg:w-3/4 w-full text-trans text-grad5 pr md:text-3xl sm:text-2xl text-xl lg:text-4xl xl:text-[40px]/[63px]'>Subscribe for Exclusive
                            Updates & Insights.</p>
                        <div className="lg:w-3/4 w-full flex sm:flex-row flex-col mt-[30px] gap-5 ">
                            <div className='flex-1'>
                                <input type="text" className='bg-white placeholder:text-base placeholder:text-[#06050c] py-6 px-8 h-[72px] w-full rounded-lg' placeholder='Enter Your Email Address' />
                            </div>
                            <button className='clip game_download rounded-lg flex gap-1.5 items-center justify-center h-[72px] px-5  rounded-br-[24px]'>
                                <span className='text-base text-white text-center pbd tracking-wide'>Sign up now</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex md:flex-row lg:flex-nowrap flex-wrap flex-col items-start justify-between lg:mx-14 md:mx-7 mx-4 lg:gap-0 gap-10">
                    <div className='logo-footer xl:w-1/5 lg:w-1/6 md:w-1/5 w-1/4 mx-auto'>
                        <img src={siteLogo2} alt="" className='w-full h-full object-contain' />
                    </div>
                    <div className='lg:w-1/2 w-full  flex flex-col justify-end'>
                        <div className="flex flex-row flex-wrap lg:flex-nowrap xl:gap-10 lg:gap-10 gap-6 justify-center">
                            <div>
                                <ul>

                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>People</h3>
                                    <li>
                                        <NavLink to={'/Team'}>
                                            <span className="text-sm/7 pr text-[#A9C5E4] ">Team</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/Comesoon'}>
                                            <span className="text-sm/7 pr text-[#A9C5E4] ">About Us</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'https://armanew.s3.amazonaws.com/Arma+Litepaper.pdf'} target='blank'>
                                            <span className="text-sm/7 pr text-[#A9C5E4] ">Litepaper</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/Blog'}>
                                            <span className="text-sm/7 pr text-[#A9C5E4] ">Blog</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/Tokenomics'}>

                                            <span className="text-sm/7 pr text-[#A9C5E4] ">$AB</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/TokenPresale'}>
                                            <span className="text-sm/7 pr text-[#A9C5E4] ">Presale</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/Careers'}><span className="text-sm/7 pr text-[#A9C5E4] ">Careers</span>
                                        </NavLink></li>


                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>Tech</h3>

                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Overview</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Characters</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Game Modes</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Game Maps</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Equipment </span></NavLink></li>

                                    <li><Link to={'/'} state={{ route: 'Roadmap' }}><span className="text-sm/7 pr text-[#A9C5E4] ">Roadmap</span></Link></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Gameplay Video</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Backer</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Architecture</span></NavLink></li>
                                    <li><NavLink to={'/'}><span className="text-sm/7 pr text-[#A9C5E4] ">Publications</span></NavLink></li>
                                    <li><NavLink to={'/Docs'}><span className="text-sm/7 pr text-[#A9C5E4] ">Docs</span></NavLink></li>

                                </ul>
                            </div>
                            <div>
                                <ul>

                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>Activity</h3>
                                    <li><NavLink to={'/Comesoon'}><span className="text-sm/7 pr text-[#A9C5E4] ">Overall</span></NavLink>
                                    </li>
                                    <li><NavLink to={'/Comesoon'}><span className="text-sm/7 pr text-[#A9C5E4] ">Development</span></NavLink>
                                    </li>
                                    <li><NavLink to={'/Comesoon'}><span className="text-sm/7 pr text-[#A9C5E4] ">Community</span></NavLink>
                                    </li>
                                    <li><NavLink to={'/Comesoon'}><span className="text-sm/7 pr text-[#A9C5E4] ">Staking</span></NavLink>
                                    </li>

                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <h3 className='mb-2.5 psbd text-base/6 text-white'>Resources</h3>

                                    <li><NavLink to={'/Tokenomics'}><span className="text-sm/7 pr text-[#A9C5E4] ">Tokenomics</span></NavLink></li>
                                    <li><NavLink to={'/Tokenomics'}><span className="text-sm/7 pr text-[#A9C5E4] ">Token Distribution</span></NavLink></li>
                                    <li><NavLink to={'/Tokenomics'}><span className="text-sm/7 pr text-[#A9C5E4] ">Vesting Schedule</span></NavLink></li>
                                    <li><NavLink to={'/Tokenomics'}><span className="text-sm/7 pr text-[#A9C5E4] ">Storage</span></NavLink></li>
                                    {/* <li><NavLink to={'/Tokenomics'}><span className="text-sm/7 pr text-[#A9C5E4] ">Token</span></NavLink></li> */}

                                </ul>
                            </div>
                        </div>
                        <div className="mt-16 flex flex-row flex-wrap justify-center items-center gap-1.5">

                            <a href="https://t.me/armathebattleground" target='blank'>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={telegram} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>

                            <a href="#">
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={meta} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                            <a href="#">
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={insta} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                            <a href="#">
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={tiktok} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                            <a href="https://discord.gg/V3EQBsFXEK" target='blank'>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={discord} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                            <a href="https://x.com/Arma_battle" target='blank'>
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={twitterx} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                            <a href="#">
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={metro} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                            <a href="#">
                                <div className="w-[41px] h-[41px] rounded-full bg-[#231F33] flex items-center justify-center">
                                    <NavLink to={'/'}>
                                        <img src={lizert} alt="" className='w-4 h-4 object-contain' />
                                    </NavLink>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='lg:w-1/4 w-full  flex justify-center md:justify-end'>
                        <div className='flex flex-col'>
                            <h3 className='text-base/6 text-white md:text-start text-center psbd mb-2.5'>Contact Us</h3>
                            <p className='text-base/6 pmed text-trans md:text-start text-center text-grad6'>info@armablock.io</p>
                            <div className="grid grid-cols-2 mt-[29px] gap-1">
                                <div className='bg-[#20222A] w-max rounded-lg p-4 flex flex-row justify-center gap-2 items-center h-[38px]'>
                                    <img src={hackgu} alt="" className='w-[26px] h-5' />
                                    <div className=''>
                                        <p className='pr text-xs text-white mb-[0.5px]'>Hacken</p>
                                        <p className='text-white text-[9px] pr mb-0'>Audited by</p>
                                    </div>
                                </div>




                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex flex-wrap md:flex-row flex-col lg:gap-0 gap-5 items-center justify-center pb-8 mt-8  md:mx-14">
                    <h3 className='text-[#A9C5E4] text-base pr md:mr-12 text-center md:text-start'>© 2024 Arma Battle Ground. All Rights Reserved.</h3>

                    <NavLink to={"/"} className='text-[#A9C5E4] text-base pr'>

                        Privacy Policy
                    </NavLink>
                    <div className='mx-3 bg-[#424B54] h-3 w-[1px] md:block hidden'></div>

                    <NavLink to={"/"} className='text-[#A9C5E4] text-base pr'>

                        Terms and Conditions
                    </NavLink>
                    <div className='mx-3 bg-[#424B54] h-3 w-[1px] md:block hidden'></div>

                    <NavLink to={"/"} className='text-[#A9C5E4] text-base pr'>

                        Cookie Policy
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Footer