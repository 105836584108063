import React from 'react'
import PropTypes from 'prop-types'
import { armaCharecter2, charactor1 } from '../services/image'
import { Link, NavLink } from 'react-router-dom'

function MarketPlaceCard({ item, idx }) {
    return (
        <div className={`xl:pt-20 pb-20 flex flex-col ${idx % 2 == 0 ? 'md:flex-row' : 'md:flex-row-reverse'}   items-center justify-between w-11/12 xl:w-[68%] mx-auto xl:gap-36 lg:gap-28 gap-5`}>
            <div className='lg:w-[280px] lg:h-[530px] h-56 w-36 md:w-44 md:h-96 flex items-center'>
                <img src={item.image} alt="" className='w-full h-auto'/>
            </div>
            <div className='flex flex-col flex-1'>
                <h3 className='md:mb-6 mb-2 xl:text-[40px]/[60px] lg:text-5xl text-2xl pr text-grad2 text-start'> {item.name}</h3>
                <p className='pr text-white text-sm/6 xl:mb-7 lg:mb-4 mb-2 text-start md:line-clamp-5 ld:line-clamp-7 line-clamp-2'>{item.description}</p>
                {/* <p className='pr text-s text-white text-sm/6 text-start md:line-clamp-5 ld:line-clamp-7 line-clamp-2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> */}
                <Link to="/Docs" state={{ ...item }}
                    className='read_more_btn py-2.5 px-[16px] border-solid border w-max mt-[30px]' >
                    <span className='text-center text-sm/6 pr text-white'>Read More</span>
                </Link>
            </div>
        </div>
    )
}

MarketPlaceCard.propTypes = {}

export default MarketPlaceCard
