import React, { memo, useCallback, useMemo, useEffect, useRef, useState } from 'react'
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

function Partical() {

    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            await loadAll(engine);
            //await loadFull(engine);
            // await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const options = useMemo(
        () => ({
            // 'id': 'tsparticles',
            "autoPlay": true,
            "background": {
                "color": {
                    "value": "#0b0c17"
                },
                "image": "",
                "position": "",
                "repeat": "",
                "size": "",
                "opacity": 1
            },
            "backgroundMask": {
                "composite": "destination-atop",
                "cover": {
                    "color": {
                        "value": "#FFBE98"
                    },
                    "opacity": 1
                },
                "enable": false
            },
            "clear": true,
            "defaultThemes": {},
            "delay": 0,
            "fullScreen": {
                "enable": true,
                "zIndex": -4
            },
            "detectRetina": true,
            "duration": 0,
            "fpsLimit": 300,
            "interactivity": {
                "detectsOn": "window",
                "events": {
                    "onClick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "onDiv": {
                        "selectors": [],
                        "enable": false,
                        "mode": "bubble",
                        "type": "circle"
                    },
                    "onHover": {
                        "enable": true,
                        "mode": [],
                        "parallax": {
                            "enable": true,
                            "force": 40,
                            "smooth": 40
                        }
                    },
                    "resize": {
                        "delay": 0.5,
                        "enable": true
                    }
                },
                "modes": {
                    "trail": {
                        "delay": 1,
                        "pauseOnStop": false,
                        "quantity": 1
                    },
                    "attract": {
                        "distance": 200,
                        "duration": 0.4,
                        "easing": "ease-out-quad",
                        "factor": 1,
                        "maxSpeed": 50,
                        "speed": 1
                    },
                    "bounce": {
                        "distance": 200
                    },
                    "bubble": {
                        "distance": 200,
                        "duration": 0.4,
                        "mix": false,
                        "divs": {
                            "distance": 200,
                            "duration": 0.4,
                            "mix": false,
                            "selectors": []
                        }
                    },
                    "connect": {
                        "distance": 80,
                        "links": {
                            "opacity": 0.5
                        },
                        "radius": 60
                    },
                    "grab": {
                        "distance": 100,
                        "links": {
                            "blink": false,
                            "consent": false,
                            "opacity": 1
                        }
                    },
                    "push": {
                        "default": true,
                        "groups": [],
                        "quantity": 4
                    },
                    "remove": {
                        "quantity": 2
                    },
                    "repulse": {
                        "distance": 200,
                        "duration": 0.4,
                        "factor": 100,
                        "speed": 1,
                        "maxSpeed": 50,
                        "easing": "ease-out-quad",
                        "divs": {
                            "distance": 200,
                            "duration": 0.4,
                            "factor": 100,
                            "speed": 1,
                            "maxSpeed": 50,
                            "easing": "ease-out-quad",
                            "selectors": []
                        }
                    },
                    "slow": {
                        "factor": 3,
                        "radius": 200
                    },
                    "light": {
                        "area": {
                            "gradient": {
                                "start": {
                                    "value": "#ffffff"
                                },
                                "stop": {
                                    "value": "#000000"
                                }
                            },
                            "radius": 1000
                        },
                        "shadow": {
                            "color": {
                                "value": "#000000"
                            },
                            "length": 2000
                        }
                    }
                }
            },
            "manualParticles": [],
            "particles": {
                "bounce": {
                    "horizontal": {
                        "value": 1
                    },
                    "vertical": {
                        "value": 1
                    }
                },
                "collisions": {
                    "absorb": {
                        "speed": 2
                    },
                    "bounce": {
                        "horizontal": {
                            "value": 1
                        },
                        "vertical": {
                            "value": 1
                        }
                    },
                    "enable": false,
                    "maxSpeed": 50,
                    "mode": "bounce",
                    "overlap": {
                        "enable": true,
                        "retries": 0
                    }
                },
                "color": {
                    "value": "#FFBE98",
                    "animation": {
                        "h": {
                            "count": 0,
                            "enable": false,
                            "speed": 1,
                            "decay": 0,
                            "delay": 0,
                            "sync": true,
                            "offset": 0
                        },
                        "s": {
                            "count": 0,
                            "enable": false,
                            "speed": 1,
                            "decay": 0,
                            "delay": 0,
                            "sync": true,
                            "offset": 0
                        },
                        "l": {
                            "count": 0,
                            "enable": false,
                            "speed": 1,
                            "decay": 0,
                            "delay": 0,
                            "sync": true,
                            "offset": 0
                        }
                    }
                },
                "effect": {
                    "close": true,
                    "fill": true,
                    "options": {},
                    "type": []
                },
                "groups": {},
                "move": {
                    "angle": {
                        "offset": 0,
                        "value": 90
                    },
                    "attract": {
                        "distance": 200,
                        "enable": false,
                        "rotate": {
                            "x": 3000,
                            "y": 3000
                        }
                    },
                    "center": {
                        "x": 50,
                        "y": 50,
                        "mode": "percent",
                        "radius": 0
                    },
                    "decay": 0,
                    "distance": {},
                    "direction": "none",
                    "drift": 0,
                    "enable": true,
                    "gravity": {
                        "acceleration": 9.81,
                        "enable": false,
                        "inverse": false,
                        "maxSpeed": 50
                    },
                    "path": {
                        "clamp": true,
                        "delay": {
                            "value": 0
                        },
                        "enable": false,
                        "options": {}
                    },
                    "outModes": {
                        "default": "destroy",
                        "bottom": "destroy",
                        "left": "destroy",
                        "right": "destroy",
                        "top": "destroy"
                    },
                    "random": false,
                    "size": true,
                    "speed": 5,
                    "spin": {
                        "acceleration": 0,
                        "enable": false
                    },
                    "straight": false,
                    "trail": {
                        "enable": true,
                        "length": 3,
                        "fill": {
                            "color": {
                                "value": "#000000"
                            }
                        }
                    },
                    "vibrate": false,
                    "warp": false
                },
                "number": {
                    "density": {
                        "enable": false,
                        "width": 1920,
                        "height": 1080
                    },
                    "limit": {
                        "mode": "delete",
                        "value": 0
                    },
                    "value": 0
                },
                "opacity": {
                    "value": {
                        "min": 0.7,
                        "max": 1
                    },
                    "animation": {
                        "count": 0,
                        "enable": false,
                        "speed": 7,
                        "decay": 0,
                        "delay": 0,
                        "sync": false,
                        "mode": "auto",
                        "startValue": "random",
                        "destroy": "none"
                    }
                },
                "reduceDuplicates": false,
                "shadow": {
                    "blur": 0,
                    "color": {
                        "value": "#000"
                    },
                    "enable": false,
                    "offset": {
                        "x": 0,
                        "y": 0
                    }
                },
                "shape": {
                    "close": true,
                    "fill": true,
                    "options": {},
                    "type": "circle",

                },
                "size": {
                    "value": {
                        "min": 2,
                        "max": 8
                    },
                    "animation": {
                        "count": 0,
                        "enable": false,
                        "speed": 1,
                        "decay": 0,
                        "delay": 0,
                        "sync": false,
                        "mode": "auto",
                        "startValue": "random",
                        "destroy": "none"
                    }
                },
                "stroke": {
                    "width": 0
                },
                "zIndex": {
                    "value": 0,
                    "opacityRate": 1,
                    "sizeRate": 1,
                    "velocityRate": 1
                },
                "destroy": {
                    "bounds": {},
                    "mode": "none",
                    "split": {
                        "count": 1,
                        "factor": {
                            "value": 3
                        },
                        "rate": {
                            "value": {
                                "min": 4,
                                "max": 9
                            }
                        },
                        "sizeOffset": true,
                        "particles": {}
                    }
                },
                "roll": {
                    "darken": {
                        "enable": false,
                        "value": 0
                    },
                    "enable": false,
                    "enlighten": {
                        "enable": false,
                        "value": 0
                    },
                    "mode": "vertical",
                    "speed": 25
                },
                "tilt": {
                    "value": 0,
                    "animation": {
                        "enable": false,
                        "speed": 0,
                        "decay": 0,
                        "sync": false
                    },
                    "direction": "clockwise",
                    "enable": false
                },
                "twinkle": {
                    "lines": {
                        "enable": false,
                        "frequency": 1,
                        "opacity": 1
                    },
                    "particles": {
                        "color": '#FFBE98',
                        "enable": true,
                        "frequency": 1,
                        "opacity": 1
                    }
                },
                "wobble": {
                    "distance": 5,
                    "enable": false,
                    "speed": {
                        "angle": 50,
                        "move": 10
                    }
                },
                "life": {
                    "count": 0,
                    "delay": {
                        "value": 0,
                        "sync": false
                    },
                    "duration": {
                        "value": 0,
                        "sync": false
                    }
                },
                "rotate": {
                    "value": 0,
                    "animation": {
                        "enable": false,
                        "speed": 0,
                        "decay": 0,
                        "sync": false
                    },
                    "direction": "clockwise",
                    "path": false
                },
                "orbit": {
                    "animation": {
                        "count": 0,
                        "enable": false,
                        "speed": 1,
                        "decay": 0,
                        "delay": 0,
                        "sync": false
                    },
                    "enable": false,
                    "opacity": 1,
                    "rotation": {
                        "value": 45
                    },
                    "width": 1
                },
                "links": {
                    "blink": false,
                    "color": {
                        "value": "#FFBE98"
                    },
                    "consent": false,
                    "distance": 100,
                    "enable": false,
                    "frequency": 1,
                    "opacity": 1,
                    "shadow": {
                        "blur":0,
                        "color": {
                            "value": "#FFBE98"
                        },
                        "enable": false
                    },
                    "triangles": {
                        "enable": false,
                        "frequency": 1
                    },
                    "width": 1,
                    "warp": false
                },
                "repulse": {
                    "value": 0,
                    "enabled": false,
                    "distance": 1,
                    "duration": 1,
                    "factor": 1,
                    "speed": 1
                }
            },
            "pauseOnBlur": false,
            "pauseOnOutsideViewport": true,
            "responsive": [],
            "smooth": false,
            "style": {},
            "themes": [],
            "zLayers": 100,
            "emitters": {
                "autoPlay": true,
                "fill": true,
                "life": {
                    "wait": false
                },
                "rate": {
                    "quantity": 2,
                    "delay": 0.25
                },
                "shape": {
                    "options": {},
                    "replace": {
                        "color": false,
                        "opacity": false
                    },
                    "type": "square"
                },
                "startCount": 0,
                "size": {
                    "mode": "percent",
                    "height": 0,
                    "width": 0
                },
                "direction": "none",
                "particles": {},
                "position": {
                    "x": 50,
                    "y": 50
                },
                "spawnColor": {
                    "value": "#FFBE98",
                    "animation": {
                        "h": {
                            "count": 0,
                            "enable": true,
                            "speed": 5,
                            "decay": 0,
                            "delay": 0,
                            "sync": true,
                            "offset": 0
                        },
                        "s": {
                            "count": 0,
                            "enable": false,
                            "speed": 1,
                            "decay": 0,
                            "delay": 0,
                            "sync": true,
                            "offset": 0
                        },
                        "l": {
                            "count": 0,
                            "enable": true,
                            "speed": 0,
                            "decay": 0,
                            "delay": 0,
                            "sync": true,
                            "offset": {
                                "min": 20,
                                "max": 80
                            }
                        }
                    }
                }
            },
            "motion": {
                "disable": false,
                "reduce": {
                    "factor": 4,
                    "value": true
                }
            }

        }),
        [],
    );

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
        init && <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options} />
    )
}

export default Partical