import React from 'react'

function ImageCard(image) {
  return (
      <div className='border h-[100px] flex flex-row items-center justify-center rounded-md border-[#FDE0DA] border-solid md:px-0 px-5'>
          <div className='w-full md:w-[164px] h-[50px]'>
              <img src={image.image} alt="" />
          </div>
      </div>
  )
}

export default ImageCard