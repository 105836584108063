import React from 'react'
import { personmem } from '../services/image'

function AdvisoryBoardCard() {
    return (
        <div  className='w-full flex flex-col items-center gap-5'>
            <div className='rounded-lg'>
                <img src={personmem} alt="" className='w-full h-full object-contain' />
            </div>
            <div className='teamBtmcard h-[88px] rounded-[20px] overflow-hidden flex justify-center items-center px-[1px] w-full'>
                <div className='h-[86px] mx-auto rounded-[20px] bg-[#272135] flex flex-col py-[11px] w-full px-4 items-center'>
                    <h3 className='text-grad8 text-trans mb-2.5 tex-t2xl  pm'>Hjalmar Turesson</h3>
                    <p className='text-base/8 pbd text-white'>Scientific Advisor</p>
                </div>
            </div>
        </div>
    )
}

export default AdvisoryBoardCard