import React, { Suspense, useEffect } from 'react'
import Header from '../component/Header'
import { test, armaCharecter2, bullets, charactor1, docsImage, guns, rifels, ship } from '../services/image'
import TopHeading from '../component/TopHeading'
import GradientText from '../component/GradientText'
import MarketPlaceSwiper from '../component/MarketPlaceSwiper'
import Footer from '../component/Footer'
import { WOW } from 'wowjs'
import 'animate.css';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { useLocation } from 'react-router-dom'
function Docs(props) {
    let { state } = useLocation();
    const { pathname } = useLocation();

    console.log(`state==>`, state);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.marketplaceswiper', {
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }, []);

    return (
        <div className='docs_wrapper'>
            <div className='lg:mx-[59px] mx-4 '>
                <Header />
            </div>
            <div className='h-[500px] w-full '>
                {/* <img src={docsImage} className='w-full h-full object-fill' alt="" /> */}
                {state.title_video && <img src={state.title_video} className='w-full object-fill h-full'></img>}
            </div>
            <div className='bg-center bg-cover bg-no-repeat bg-docs'>
                <div className="flex md:flex-row flex-col justify-center items-center md:items-start gap-24 pt-10 md:pt-14 lg:pt-[114px] lg:mx-[60px] md:mx-[30px] mx-6">
                    <div className='w-1/4 wow animate__animated animate__fadeInLeft'>
                        {state.image && <img src={state.image} className='w-full h-full object-contain' alt="" />}
                    </div>
                    <div className='md:w-1/2 w-full '>
                        {state.name && <h3 className='text-grad10 text-trans text-[40px]/[60px] pr mb-6 '>{state.name}</h3>}

                        {state.description && <p className='text-white text-sm/6 pr pb-[30px] wow animate__animated animate__fadeInUp' >
                            {state.description}
                        </p>}
                        {/*                            
                        <div className='mt-16 mb-[105px] wow animate__animated animate__fadeInUp'>
                            <div className='p-5 rounded-[20px] bg-[#222233] w-full'>
                                <ul className="pl-0 ">
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Real Name</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>
                                            August Montgomery Brinkman
                                        </span>
                                    </li>
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Age</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>63</span>
                                    </li>
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Home World</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>Gaea</span>
                                    </li>
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Class</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>Assault</span>
                                    </li>
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Tactical Ability</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>Whistler</span>
                                    </li>
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Passive Ability</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>Sling</span>
                                    </li>
                                    <li className="flex flex-row mb-2.5 gap-[30px]">
                                        <p className='flex flex-row mb-0 justify-between items-center w-1/4 '>
                                            <span className='pbd text-base/6  text-white  '>Ultimate Ability</span>
                                            <span className='text-base/6 text-white pr'>:</span>

                                        </p>
                                        <span className='text-base/6 pr text-white '>Tempest</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="md:pt-10 pt-5">
                <TopHeading text={'Join arma'} />
                <div className='lg:w-3/4 md:w-11/12 w-full mx-auto wow animate__animated animate__fadeInUp'>
                    <GradientText props={`Some Skills of character`} />
                </div>
                <div className="lg:mt-24 md:mt-7 mt-4  mx-auto  w-full md:w-11/12 ">
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-14 gap-10 lg:gap-[129px] items-center wow animate__animated animate__fadeInUp' >
                        {state.skills && state.skills.map((skill, idx) => {
                            return (
                                <div key={idx} className={`classcard flex flex-col  w-full justify-center mx-auto ${idx == 0 ? 'lg:col-span-3 lg:w-[22%]' : 'lg:w-11/12'}`}>
                                    <div className='h-44  mx-auto'>
                                        <img src={skill.image} alt="" className='w-full h-full object-contain' />
                                    </div>
                                    <h3 className='text-[#FF9B87] text-sm/[21px] text-center pr mt-3.5'>CLASS</h3>
                                    <p className='text-white text-2xl/[36px] text-center pr mb-4'>{skill.name}</p>
                                    <p className='text-base mb-4 text-white  text-center'>- {skill.description}</p>
                                    {/* <p className='text-base text-white  text-center'>
                                        - Can carry an extra stack of ammo in each inventory slot</p> */}
                                </div>
                            )
                        })}

                        {/* <div className="classcard flex flex-col w-full  ">
                            <div className='h-44'>
                                <img src={ship} alt="" className='w-full h-full object-contain' />
                            </div>
                            <h3 className='text-[#FF9B87] text-sm/[21px] text-center pr mt-3.5'>CLASS</h3>
                            <p className='text-white text-2xl/[36px] text-center pr mb-4'>Assault</p>
                            <p className='text-base mb-4 text-white  text-center'>- Access the hidden loot in red Weapon Supply Bins for High Value Weapon Attachments</p>
                            <p className='text-base text-white  text-center'>
                                - Can carry an extra stack of ammo in each inventory slot</p>
                        </div>

                        <div className="classcard flex flex-col w-full lg:w-10/12 ">
                            <div className='h-44'>
                                <img src={guns} alt="" className='w-full h-full object-contain' />
                            </div>
                            <h3 className='text-[#FF9B87] text-sm/[21px] text-center pr mt-3.5'>CLASS</h3>
                            <p className='text-white text-2xl/[36px] text-center pr mb-4'>Assault</p>
                            <p className='text-base mb-4 text-white  text-center'>- Access the hidden loot in red Weapon Supply Bins for High Value Weapon Attachments</p>
                            <p className='text-base text-white  text-center'>
                                - Can carry an extra stack of ammo in each inventory slot</p>
                        </div>

                        <div className="classcard flex flex-col w-full lg:w-10/12 ">
                            <div className='h-44'>
                                <img src={rifels} alt="" className='w-full h-full object-contain' />
                            </div>
                            <h3 className='text-[#FF9B87] text-sm/[21px] text-center pr mt-3.5'>CLASS</h3>
                            <p className='text-white text-2xl/[36px] text-center pr mb-4'>Assault</p>
                            <p className='text-base mb-4 text-white  text-center'>- Access the hidden loot in red Weapon Supply Bins for High Value Weapon Attachments</p>
                            <p className='text-base text-white  text-center'>
                                - Can carry an extra stack of ammo in each inventory slot</p>
                        </div> */}
                    </div>

                </div>
                {state.videos.length > 0
                    && <div className='lg:mt-28 md:mt-14 mt-10'>
                        <Suspense fallback={<div>Loading...</div>}>
                            <div className='horzontal_slider_wrapper'>
                                <div className="lg:mx-[59px] mx-4 ">
                                    <div className="md:pt-10 wow animate__animated animate__fadeInUp animate__slow">
                                        <TopHeading text={''} />
                                        <div className='lg:w-10/12 w-full mx-auto'>
                                            <GradientText props={`Character in action`} />
                                        </div>
                                        <div className='mt-10 md:mt-[60px] '>
                                            <div className="swiper marketplaceswiper">
                                                <div className="swiper-wrapper">
                                                    {state.videos.map((vid, idx) => {
                                                        return (<div key={idx} className="swiper-slide">
                                                            <div className='swiper_image_overflow lg:w-3/5 mx-auto'>
                                                                <video src={vid} className='object-contain h-100 w-100' height={200} width={'100%'} controls >
                                                                </video>
                                                                {/* src={vid}  */}
                                                            </div>
                                                        </div>)
                                                    })}


                                                </div>
                                                <div className="swiper-pagination !hidden"></div>
                                                <div className="swiper-button-prev  md:!w-[60px] md:!h-[60px] lg:!left-[10%] PREV rounded-md"></div>
                                                <div className="swiper-button-next  NEXT md:!w-[60px] md:!h-[60px] lg:!right-[10%] rounded-md"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Suspense>
                    </div>
                }

            </div>
            <Footer />
        </div >
    )
}

export default Docs