
import './App.css';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Home from './page/Home';
import { register } from 'swiper/element/bundle';
import Team from './page/Team';
import Careers from './page/Careers';
import Docs from './page/Docs';
import BlogDetail from './page/BlogDetail';
import Tokenomics from './page/Tokenomics';
import Blog from './page/Blog';
import TokenPresale from './page/TokenPresalePage';
import Comesoon from './page/Comesoon';


register();
function App() {

 

  // if (init) {
  //   return (
  //     <Particles
  //       id="tsparticles"
  //       particlesLoaded={particlesLoaded}
  //       options={options}
  //     />
  //   );
  // }

 


  return (
    
      <BrowserRouter>
        <div className='page-wrapper' id='page-wrapper'>
          <Routes>
            <Route path="/" element={<Home />} />
          <Route path="/Comesoon" element={<Comesoon />} />
            <Route path="Team" element={<Team />} />
            <Route path="Careers" element={<Careers />} />
            <Route path="Docs" element={<Docs />} />
            <Route path="BlogDetail" element={<BlogDetail />} />
            <Route path="Tokenomics" element={<Tokenomics />} />
            <Route path="TokenPresale" element={<TokenPresale />} />
            <Route path="Blog" element={<Blog />} />
          </Routes>
        </div>
      </BrowserRouter>
   
  );
}

export default App;
