import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import { siteLogo, telegram, window, twitter, pintrust, rotate, uuper, dinos, kucoin3, bitgit, mexc2, pancakes, chaingpt, ejit, seedify, pool, hhh, loack } from '../services/image'
import { Link, NavLink } from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import SubmenuCard from './SubmenuCard'
const Header = memo((props) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    return (
        <div className='flex flex-row justify-between items-center gap-10  py-3.5 w-full'>
            <div className='flex flex-row items-center'>
                <div className='flex flex-row  items-center xl:gap-10 lg:gap-10'>
                    <NavLink to={'/'} className='lg:h-[70px] md:h-14 h-12  lg:w-16  md:w-16 w-12'>
                        <img src={siteLogo} alt="site-logo" className='w-full h-full object-contain' />
                    </NavLink>
                    <div className='nav-item'>
                        <ul className='pl-0 list-none lg:flex hidden xl:gap-11 lg:gap-5 flex-row'>
                        <li className=''>
                                <NavLink to={'/'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>About Us</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Careers'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Careers</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'https://armanew.s3.amazonaws.com/Arma+Litepaper.pdf'} target='blank'>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Litepaper</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Blog'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Blog</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Tokenomics'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>$AB</span>
                                </NavLink>

                            </li>
                            <li className=''>
                                <NavLink to={'/TokenPresale'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Presale</span>
                                </NavLink>
                            </li>
                            {/* <li className='relative group/card'>
                                <div>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>OFN</span>
                                </div>
                                <div className='absolute top-full  group-hover/card:block hidden '>
                                    <SubmenuCard />
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='flex  flex-row items-center justify-end lg:gap-6 xl:gap-[38.88px]'>
                <div className='lg:flex hidden flex-row items-center gap-6'>
                    <a href='#' className="xl:w-5 w-4">
                        <img src={telegram} alt="" />
                    </a>
                    <a href='#' className="xl:w-5 w-4">
                        <img src={twitter} alt="" />
                    </a>
                    <a href='#' className="xl:w-5 w-4">
                        <img src={uuper} alt="" />
                    </a>
                    <a href='#' className="xl:w-5 w-4">
                        <img src={pintrust} alt="" />
                    </a>
                    <a href='#' className="xl:w-5 w-4">
                        <img src={dinos} alt="" />
                    </a>
                    <a href='#' className="xl:w-5 w-4">
                        <img src={rotate} alt="" />
                    </a>
                </div>
                <div className='lg:flex hidden flex-row items-center gap-[22px]'>
                    <button className=' game_download clip w-32 xl:h-[61px] lg:h-[52px] p-[1px] overflow-hidden rounded-md'>
                        <div className='w-full h-full flex flex-row items-center justify-center   rounded-md  clip game_download2'>
                            <span className='text-[#FF9B97] text-base pbd tracking-wide'>Buy Token</span>
                        </div>

                    </button>
                    <button className='clip game_download rounded-lg flex gap-1.5 items-center justify-center xl:h-[61px] lg:h-[52px] px-4 rounded-br-[24px]'>
                        <span className='xl:w-[22px] w-4 xl:h-[22px] h-4'>
                            <img src={window} alt="" />
                        </span>
                        <span className='xl:text-base text-sm text-white text-center pbd tracking-wide'>Download</span>
                    </button>

                </div>
                <button className="w-5 h-5 lg:hidden block" onClick={toggleDrawer}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="20px" height="20px" viewBox="0 0 4096.000000 4096.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,4096.000000) scale(0.100000,-0.100000)"
                            fill="#FFFFFF" stroke="none">
                            <path d="M2970 37669 c-780 -74 -1507 -423 -2049 -984 -446 -462 -748 -1046
    -865 -1674 -79 -420 -72 -896 21 -1311 170 -769 602 -1441 1228 -1910 514
    -385 1096 -603 1732 -650 106 -8 5044 -10 17543 -8 16531 3 17401 4 17510 21
    428 65 736 160 1073 328 613 308 1137 832 1445 1445 342 681 437 1454 272
    2199 -251 1129 -1084 2043 -2189 2399 -223 72 -473 123 -716 146 -134 12
    -34874 12 -35005 -1z"/>
                            <path d="M3080 24569 c-673 -40 -1318 -288 -1850 -709 -133 -105 -411 -383
    -519 -520 -696 -878 -896 -2055 -531 -3115 99 -285 207 -502 381 -760 311
    -461 725 -828 1219 -1079 366 -186 696 -287 1125 -343 135 -17 761 -18 17575
    -18 16590 0 17442 1 17573 18 723 92 1340 375 1855 850 1023 943 1335 2436
    777 3723 -105 243 -266 511 -439 729 -102 128 -332 363 -460 468 -553 459
    -1199 713 -1926 757 -196 12 -34584 11 -34780 -1z"/>
                            <path d="M3020 11459 c-1389 -112 -2556 -1083 -2910 -2424 -119 -449 -141
    -920 -64 -1385 90 -546 303 -1032 650 -1480 117 -153 407 -441 562 -560 460
    -354 964 -569 1552 -662 l145 -23 17520 0 c16808 0 17525 1 17640 18 735 110
    1342 404 1849 897 543 528 871 1186 973 1955 21 159 24 609 5 755 -34 261 -76
    452 -148 672 -280 859 -924 1577 -1746 1950 -314 142 -601 222 -1008 280 -66
    9 -3876 12 -17500 14 -9578 1 -17462 -3 -17520 -7z"/>
                        </g>
                    </svg>
                </button>
                <div className='lg:hidden'>
                    <Drawer
                        open={isOpen}
                        onClose={toggleDrawer}
                        direction='right'
                        className='bg-[#0b0c17] p-10 '
                    >
                        <NavLink to={'/'} className='mx-auto mb-10'>
                            <img src={siteLogo} alt="site-logo" className='w-20 h-12 object-contain mx-auto mb-10' />
                        </NavLink>
                        <ul className='pl-0 list-none justify-center items-center flex flex-col gap-5 '>
                            <li className=''>
                                <NavLink to={'/Team'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Team</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Careers'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Careers</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Docs'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Docs</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Blog'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Blog</span>
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink to={'/Tokenomics'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>$AB</span>
                                </NavLink>

                            </li>
                            <li className=''>
                                <NavLink to={'/TokenPresale'}>
                                    <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>Presale</span>
                                </NavLink>
                            </li>
                            {/* <li className='relative group/card'>
                            <div>
                                <span className='pr text-white xl:text-base lg:text-sm tracking-[4%]'>OFN</span>
                            </div>
                            <div className='absolute   group-hover/card:block hidden left-[-11rem] top-[-16rem]'>
                                <SubmenuCard />
                            </div>

                        </li> */}
                        </ul>
                    </Drawer>
                </div>
                
            </div>

        </div>
    )
})

Header.propTypes = {}

export default Header