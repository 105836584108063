import PropTypes from 'prop-types'
import React, { memo, useEffect } from 'react'
import { bitget, hackgu, kucoin, mexc, pancakes, solidProof, solidProoSP } from '../services/image'
import { WOW } from 'wowjs'
import 'animate.css';
const Banner = memo((props) => {
    useEffect(() => {
        const wow = new WOW(
            {
                boxClass: 'wow',      // default
                animateClass: 'animated', // default
                offset: 0,          // default
                mobile: true,       // default
                live: false        // default
            }
        )
        wow.init();
    }, [])
    return (
        <div className='md:mt-[50px] xl:mb-[80px] '>
            <div className="flex lg:flex-row flex-col gap-5 lg:gap-0 flex-1">
                <div className='flex flex-col'>
                    <p className='pr text-[#D9D9D9] tracking-[4%] text-base sm:text-lg md:text-xl lg:text-4xl/[56px] xl:w-[45%] lg:w-3/4 lg:text-start text-center'>The All New On-Chain Evolution</p>
                    <>

                        <h2 className=' xl:text-[80px]/[120px] text-[32px] sm:text-[45px] md:text-[60px] lg:text-[70px] tracking-[4%] pr xl:text-start text-center wow animate__animated animate__fadeInUp'>
                            <span className=' text-[#FFBE98] font-semibold'>

                                Arma:
                            </span>
                            <br />
                            <span className='text-[#FFBE98] font-semibold'>The  Battle Ground</span>
                        </h2>

                        <p className='lg:text-2xl/9 xl:text-start text-center text-xl text-white pr mt-4 xl:w-3/5 lg:w-4/5 w-full'>Experience the Next Frontier
                            in Play-to-Earn Gaming</p>
                        <div className="flex sm:flex-row flex-col items-center justify-center lg:justify-start  lg:mt-16 mt-4 gap-8">
                            <button className='clip flex items-center justify-center py-[18.5] px-12 h-[61px] max-w-[215px] lg:max-w-[180px] rounded-lg lunch-game'>
                                <span className='text-white text-nowrap text-base pbd text-center'>Launch Game</span>
                            </button>
                            <button className='clip flex items-center justify-center py-[18.5] px-12 h-[61px] rounded-lg bg-[#1E1E2D]'>
                                <span className='text-white text-base pbd text-center'>Buy Now</span>
                            </button>
                        </div>
                    </>
                </div>

                <div className='h-full xl:mt-0 lg:mt-20  lg:w-full xl:w-auto flex  items-end self-end mx-auto wow animate__animated animate__fadeInUp xl:pb-0 lg:pb-11'>
                    <div className='w-full xl:w-auto'>
                        <h3 className='xl:text-2xl/6 text-2xl/6 lg:text-3xl text-center text-market mb-3.5  lg:mb-6 xl:mb-[15px]'>Available on</h3>
                        <div className='w-full flex flex-row lg:flex-nowrap  xl:justify-start lg:justify-center  flex-wrap xl:gap-2 gap-2 lg:gap-8'>
                          <div className='md:w-[101px] md:h-[95px] sm:w-[80px] sm:h-[80px] w-12 h-12 '><img src={pancakes} alt="" /></div>
                        </div>
                        <div className="mt-8 sm:flex-row flex-col flex gap-5">
                            <div className='bg-[#20222A] w-[200px] rounded-xl py-[18px] flex flex-row justify-center gap-2 items-center h-[61px]'>
                                <img src={solidProoSP} alt="" className='w-[34px]' />
                                <div className=''>
                                    <p className='pr text-xs text-white mb-[0.5px]'>SOLID Proof</p>
                                    <p className='text-white text-[9px] pr mb-0'>Audited by</p>
                                </div>
                            </div>
                            {/* <div className='bg-[#20222A] w-[200px] rounded-xl py-[18px] flex flex-row justify-center gap-2 items-center h-[61px]'>
                                <img src={hackgu} alt="" className='w-[34px] h-6' />
                                <div className=''>
                                    <p className='pr text-xs text-white mb-[0.5px]'>Hacken</p>
                                    <p className='text-white text-[9px] pr mb-0'>Audited by</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

Banner.propTypes = {}

export default Banner