import React from 'react';
import PropTypes from 'prop-types';

function MarketPlaceCardSkeleton() {
    return (
        <div className="xl:pt-20 pb-20 flex flex-col md:flex-row items-center justify-between w-11/12 xl:w-[68%] mx-auto xl:gap-36 lg:gap-28 gap-5">
            <div className='lg:w-[280px] lg:h-[530px] h-56 w-36 md:w-44 md:h-96 flex items-center bg-gray-500'>
                {/* Placeholder for image */}
            </div>
            <div className='flex flex-col flex-1'>
                <div className='h-10 bg-gray-500 mb-4' /> {/* Placeholder for title */}
                <div className='h-6 bg-gray-500 mb-2' /> {/* Placeholder for description */}
                <div className='h-10 w-24 bg-gray-500' /> {/* Placeholder for button */}
            </div>
        </div>
    );
}

export default MarketPlaceCardSkeleton