import React, { useEffect, useState } from 'react'
import TokenPresale from '../component/TokenPresale'
import Header from '../component/Header'
import Footer from '../component/Footer'

function TokenPresalePage() {
    return (
        <>
        <div className='lg:mx-[59px] mx-4 '>
        <Header />  
        </div>
        <TokenPresale />
        <Footer />
        </>
    )
}
export default TokenPresalePage
